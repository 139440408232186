function Title() {
    return (
        <div id="full-title box">
            <h1>
                Bearable.
            </h1>
            <h2>
                Bowdoin Essential Academic Resource And Bulletin that Links Everywhere
            </h2>
        </div>
    );
}

export default Title;
